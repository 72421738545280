const socialFb = () => {
    try {
      window.fbAsyncInit = function() {
        FB.init({
          appId      : '1248540642909327', // Tu app ID de Facebook
          cookie     : true,
          xfbml      : true,
          version    : 'v21.0' // Versión de la API de Facebook
        });
  
        FB.AppEvents.logPageView();   
      };
  
      (function(d, s, id){
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {return;}
        js = d.createElement(s); js.id = id;
        js.src = "https://connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
      }(document, 'script', 'facebook-jssdk'));
  
      // Función para iniciar sesión con Facebook
      const loginWithFacebook = () => {
        FB.login(function(response) {
          if (response.authResponse) {
            console.log('Bienvenido, ' + response.authResponse.userID);
            // Aquí puedes gestionar la respuesta, por ejemplo, obtener datos del usuario o redirigir
            getUserData();
          } else {
            console.log('Usuario canceló el inicio de sesión');
          }
        }, {scope: 'public_profile,email'}); // Puedes añadir más permisos si los necesitas
      };
  
      // Función para obtener datos del usuario después de un inicio de sesión exitoso
      const getUserData = () => {
        FB.api('/me?fields=id,name,email', function(response) {
          console.log('Datos del usuario:', response);
          // Aquí puedes manejar los datos obtenidos, como almacenar el token de acceso, mostrar el nombre del usuario, etc.
        });
      };
  
      // Añadir el botón de login con Facebook en el DOM
      const button = document.createElement('button');
      button.textContent = 'Iniciar sesión con Facebook';
      button.onclick = loginWithFacebook;
  
      // Coloca el botón donde desees en el HTML, por ejemplo en un contenedor con ID 'facebook-login-container'
      const container = document.getElementById('facebook-login-container');
      if (container) {
        container.appendChild(button);
      }
  
    } catch (error) {
      console.error('Error al inicializar el SDK de Facebook:', error);
    }
  };
  
  export { socialFb };