const searchG = ()=>{
    try {
        jQuery(document).ready(function($) {
            $("#search-icon").on("click", function() {
              // Obtener el término de búsqueda
              const searchQuery = $("#search-input").val().trim();
          
              // Construir la URL de búsqueda
              const searchUrl = `${window.location.origin}/?s=${encodeURIComponent(searchQuery)}`;
          
              // Redirigir al usuario a la página de resultados de búsqueda de WordPress
              window.location.href = searchUrl;
            });
          });
} catch (error) {

}

}
export{searchG}