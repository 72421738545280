const LoginForm = (()=>{

    const LoginFormConfig = () => {
        const form = document.querySelector('#login-form');
        const modalTerms = document.querySelector('#modal-terms');
        const formwhitpasw = document.querySelector('#login-form-password');
        form?.addEventListener('submit', (event)=>{
            

            /*if(form.dataset.token == '1asd123as1asdqeq'){
                modalTerms.classList.add('active');
            }*/

        });
        formwhitpasw?.addEventListener('submit', ()=>{

            if(formwhitpasw.dataset.token == '1asd123as1asdqeq'){
               //formwhitpasw.submit()
            }

        });
    }

    const LoginTerms = () => {
        const modalButton = document.querySelector('#modal-terms button');
        const modalTerms = document.querySelector('#modal-terms input');

        modalTerms?.addEventListener('click', ()=>{
            modalTerms.checked ? modalButton.removeAttribute('disable') : modalButton.setAttribute('disable', true);
        });
    }

    const LoginEnablePassword = () => {
        const modalButton = document.querySelector('#modal-terms button');
        const formLogin = document.querySelector('#login-form');
        const formPassword = document.querySelector('#login-form-password');
        const inputEmail = document.querySelector('#login-form #email');
        const dinamicEmailParagraph = document.querySelector('#dinamic-email');
        const dinamicEmailParagraphtwo = document.querySelector('#user_login');

        modalButton?.addEventListener('click', ()=>{
            formLogin.classList.add('hidden');
            formPassword.classList.remove('hidden');
            dinamicEmailParagraph.textContent = (inputEmail?.value || '');
            dinamicEmailParagraphtwo.value = (inputEmail?.value || '');
        });
    }

    return {
        handleLoginForm : function(){
            try { LoginFormConfig(); } catch (error) { console.log(error) }
            try { LoginTerms(); } catch (error) { console.log(error) }
            try { LoginEnablePassword(); } catch (error) { console.log(error) }
        }
    }

})();


const handleLoginForm = ()=>{
    LoginForm.handleLoginForm();
}

export { handleLoginForm }