const citiesSearch = () => {
    try {
        jQuery(document).ready(function($) {
            // Mapa de países a códigos (puedes expandir esta lista según los países que tengas en la base de datos)
            var countryCodeMap = {
                'Mexico': 'MX',
                'Colombia': 'CO',
                'Ecuador': 'EC',
                'Chile': 'CL',
                'Perú': 'PE',
                'Honduras': 'HN'
            };
        
            // Cuando se cambie el país, actualizar las ciudades disponibles
            $('#country').on('change', function() {
                var selectedCountry = $(this).val(); // Obtener el valor del país seleccionado
                var countryCode = countryCodeMap[selectedCountry]; // Obtener el código del país
        
                // Verificar si hay un país seleccionado
                if (countryCode) {
                    // Realizar una solicitud AJAX para obtener las ciudades del país seleccionado
                    $.ajax({
                        url: citiescont, // URL de la petición AJAX de WordPress
                        type: 'GET',
                        data: {
                            action: 'buscar_ciudades_por_pais', // Acción personalizada de WordPress
                            country_code: countryCode // Código de país seleccionado
                        },
                        success: function(response) {
                            if (response.success) {
                                var suggestions = response.data.cities;
                                // Limpiar la lista de sugerencias de ciudades
                                $('#city-suggestions').html('');
                                
                                // Agregar las ciudades a la lista de sugerencias
                                suggestions.forEach(function(city) {
                                    $('#city-suggestions').append('<li class="suggestion-item">' + city + '</li>');
                                });
                            } else {
                                $('#city-suggestions').html('<li>No se encontraron ciudades para este país.</li>');
                            }
                        }
                    });
                } else {
                    // Si no hay país seleccionado, limpiar las sugerencias
                    $('#city-suggestions').html('');
                }
            });
        
            // Seleccionar una ciudad de la lista de sugerencias
            $(document).on('click', '.suggestion-item', function() {
                var selectedCity = $(this).text();
                $('#cityCountry').val(selectedCity); // Establecer el valor de la ciudad seleccionada en el input
                $('#city-suggestions').html(''); // Limpiar las sugerencias
            });
        
            // Hacer editable el campo de entrada cuando el usuario haga clic en él
            $('#cityCountry').on('click', function() {
                if ($('#cityCountry').val() !== '') {
                    $(this).prop('readonly', false); // Hacer editable el campo
                    $('#city-suggestions').html(''); // Limpiar las sugerencias cuando se vuelve editable
                }
            });
        });
        
    } catch (error) {

    }
}
export { citiesSearch };

const contryIva = () => {
    try {
        jQuery(document).ready(function ($) {
            // Mapa de los códigos de país
            var countryCodeMap = {
                "Colombia": "CO",
                "Ecuador": "EC",
                "Chile": "CL",
                "Perú": "PE",
                "Honduras": "HN"
            };
        
            // Obtén el valor del país desde el servidor (esto se debe pasar desde PHP a JavaScript)
            var billingCountry = $('#billing-country').data('country');
            console.log('País desde el DOM:', billingCountry);
            // Si billingCountry tiene valor, seleccionamos el país en el select
            if (billingCountry && countryCodeMap[billingCountry]) {
            $('#country').val(billingCountry); // Establece el país seleccionado
            var countryCode = countryCodeMap[billingCountry];
        
            // Llama AJAX si ya tiene un valor por defecto
            $.ajax({
                url: ivataxesajax,
                method: 'POST',
                data: {
                    action: 'get_tax_rate',
                    country_code: countryCode
                },
                success: function (response) {
                    if (response.success && response.data && response.data.tax_rate) {
                    var taxRate = response.data.tax_rate;
                    var rate = parseFloat(taxRate.tax_rate).toFixed(0); // Redondear a entero
                    var name = taxRate.tax_rate_name;
        
                    $('.profile-billing__iva').html(`<small>+${rate}% ${name}</small>`);
                    } else {
                    var errorMessage = response.data?.message || 'No se encontró información de impuestos';
                    $('.profile-billing__iva').html(`<small>${errorMessage}</small>`);
                    }
                },
                error: function (xhr, status, error) {
                    $('.profile-billing__iva').html(`<small>Error inesperado: ${error}</small>`);
                    console.error('Error en la solicitud AJAX:', error);
                }
            });
            }
        
            // Escucha el cambio en el select de país
            $('#country').on('change', function () {
            var selectedCountry = $(this).val();
            var countryCode = countryCodeMap[selectedCountry];
            console.log('País seleccionado:', selectedCountry, countryCode);
        
            if (!countryCode) {
                $('.profile-billing__iva').html('<small>Error: Código de país no encontrado</small>');
                return;
            }
        
            $.ajax({
                url: ivataxesajax,
                method: 'POST',
                data: {
                    action: 'get_tax_rate',
                    country_code: countryCode
                },
                success: function (response) {
                    if (response.success && response.data && response.data.tax_rate) {
                        var taxRate = response.data.tax_rate;
                        var rate = parseFloat(taxRate.tax_rate).toFixed(0); // Redondear a entero
                        var name = taxRate.tax_rate_name;
        
                        $('.profile-billing__iva').html(`<small>+${rate}% ${name}</small>`);
                    } else {
                        var errorMessage = response.data?.message || 'No se encontró información de impuestos';
                        $('.profile-billing__iva').html(`<small>${errorMessage}</small>`);
                    }
                },
                error: function (xhr, status, error) {
                    $('.profile-billing__iva').html(`<small>Error inesperado: ${error}</small>`);
                    console.error('Error en la solicitud AJAX:', error);
                }
            });
            });
        });
    } catch (error) {

    }
}

  export { contryIva };
