
import "../sass/main.scss";
//Components
import { 
    HeaderTypeCurrency, 
    HeaderHamburger, 
    DarkLightMode, 
    handleSwiper, 
    handleTab ,
    handlePopup,
    handleLoader,
    handleFilterRange,
    handleFilter,
    handleToggle,
    handleSelect,
    handleShowMore,
    handleEnableForm,
    searchG,
    logingx,
    logeoGX,
    interacciones,
    interaccionesregister,
    registerGX,
    skiptbutton,
    registertutorGX,
    actualizadatosformuno,
    registerLinksGX,
    actualizartutor,
    actualizarContrasena,
    actualizaravatar,
    interaccionesmodal,
    AgregarBilling,
    fondoswallet,
    eliminardecarrito,
    socialFb,
    recovery,
    contryIva,
    citiesSearch,
    //noticesajax
    
} from './components/index';
//Validation for forms
import { 
    ValidateFormHandleEvent,
 } from './formValidation/Index';
//Forms
import { 
    handleLoginForm,
    handleRegisterForm
} from './form/index';

window.addEventListener('load', ()=>{
    HeaderTypeCurrency();
    HeaderHamburger();
    DarkLightMode();
    handleSwiper();
    handleTab();
    handlePopup();
    ValidateFormHandleEvent();
    handleFilterRange();
    handleFilter();
    handleToggle();
    handleSelect();
    handleShowMore();
    handleEnableForm();
    //Forms
    socialFb();
    searchG();
    handleLoginForm();
    handleRegisterForm();
    logingx();
    logeoGX();
    interacciones();
    interaccionesregister();
    registerGX();
    skiptbutton();
    registertutorGX();
    actualizadatosformuno();
    registerLinksGX();
    actualizartutor();
    actualizarContrasena();
    actualizaravatar();
    interaccionesmodal();
    AgregarBilling();
    fondoswallet();
    eliminardecarrito();
    recovery();
    contryIva();
    citiesSearch();
    
});

window.addEventListener('DOMContentLoaded', ()=>{
    
    var templateUrl = object_name.templateUrl;

    
    var thecountrycurrency = document.getElementById('countrycurrency');
    var links = thecountrycurrency.querySelectorAll('a[rel]');
    links.forEach(function(link) {
        var currency = link.getAttribute('rel'); // Obtiene el valor del atributo 'rel'

        if (currency === 'COP') {
            link.innerHTML = '<img src="'+templateUrl+'/images/header/flagcolombia.svg" alt="Bandera GamerXperience"> COL';
        } else if (currency === 'USD') {
            link.innerHTML = '<img src="'+templateUrl+'/images/header/flagecuador.svg" alt="Bandera GamerXperience"> ECU';
        } else if (currency === 'PEN') {
            link.innerHTML = '<img src="'+templateUrl+'/images/header/flagperu.svg" alt="Bandera GamerXperience"> PER';
        } else if (currency === 'MXN') {
            link.innerHTML = '<img src="'+templateUrl+'/images/header/flagmexico.svg" alt="Bandera GamerXperience"> MEX';
        } else if (currency === 'CLP') {
            link.innerHTML = '<img src="'+templateUrl+'/images/header/flagchile.svg" alt="Bandera GamerXperience"> CHL';
        } else if (currency === 'HNL') {
            link.innerHTML = '<img src="'+templateUrl+'/images/header/flaghonduras.svg" alt="Bandera GamerXperience"> HND';
        }
    })

    handleLoader();
});

document.addEventListener('DOMContentLoaded', function () {
    // Obtener la fecha actual
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1; // Mes actual (1-12)
    const currentDay = currentDate.getDate();
  
    const tutorYear = document.getElementById('tutor_year');
    const tutorMonth = document.getElementById('tutor_month');
    const tutorDay = document.getElementById('tutor_day');
  
    // Llenar los años hasta el 2007 como máximo
    for (let year = currentYear - 18; year >= 1900; year--) {
        const option = document.createElement('option');
        option.value = year;
        option.textContent = year;
        tutorYear.appendChild(option);
    }
  
    // Función para comprobar los días según el mes y si el año es bisiesto
    function getDaysInMonth(month, year) {
        return new Date(year, month, 0).getDate();
    }
  
    // Actualizar los días dependiendo del mes y año
    function updateDays() {
        const selectedYear = parseInt(tutorYear.value);
        const selectedMonth = parseInt(tutorMonth.value);
  
        if (!selectedYear || !selectedMonth) return;
  
        // Obtener el número de días del mes
        const daysInMonth = getDaysInMonth(selectedMonth, selectedYear);
  
        // Limpiar los días previos
        tutorDay.innerHTML = '<option value="" selected disabled>Día</option>';
  
        // Llenar los días según el mes y año
        for (let day = 1; day <= daysInMonth; day++) {
            const option = document.createElement('option');
            option.value = day < 10 ? '0' + day : day;
            option.textContent = day < 10 ? '0' + day : day;
            tutorDay.appendChild(option);
        }
    }
  
    // Validación del día de nacimiento según los valores actuales
    function validateDate() {
        const selectedYear = parseInt(tutorYear.value);
        const selectedMonth = parseInt(tutorMonth.value);
        const selectedDay = parseInt(tutorDay.value);
  
        // Verificar si la persona tiene más de 18 años
        if (selectedYear && selectedMonth && selectedDay) {
          const birthDate = new Date(selectedYear, selectedMonth - 1, selectedDay);
          const age = currentYear - birthDate.getFullYear();
      
          if (age < 18) {
              alert('Debes tener al menos 18 años.');
              tutorDay.value = '';  // Limpiar el campo del día
              return false;
          } else if (age === 18) {
              if (selectedMonth > currentMonth || (selectedMonth === currentMonth && selectedDay > currentDay)) {
                  alert('Debes tener al menos 18 años.');
                  tutorDay.value = '';  // Limpiar el campo del día
                  return false;
              }
          }
      }
  
        return true;
    }
  
    tutorYear.addEventListener('change', function () {
        updateDays();
        validateDate();
    });
  
    tutorMonth.addEventListener('change', function () {
        updateDays();
        validateDate();
    });
  
    tutorDay.addEventListener('change', function () {
        validateDate();
    });
  });
  
  